<template>
    <v-container id="home" fill-height fluid>
        <v-row>
            <v-col>
                <h1 class="text-center">PÁGINA NÃO FOI ENCONTRADA, ESTAMOS TE REDIRECIONANDO</h1>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>


export default {
  name: "Home",
  data: () => ({
    links: [
      
    ]
  }),
  async created() {
    location.href = "/";
  },
  methods: {

  },
};
</script>